.page .logo--blue {
    display: none;
}

.logo--blue {
    max-width: 60px;
    position: absolute;
    bottom: 35px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0.28;
    transition: 0.2s;
}

.logo--blue:hover {
    opacity: 1;
}

.today {
    background: @white;
    padding: 15px @base-padding;
    overflow: hidden;

    header {
        border-bottom: 1px solid @light-gray;
        padding-bottom: 30px;
        text-align: center;

        h1 {
            font-size: @f30;
            margin: 10px 0 8px;

            .date {
                .year {
                    color: @gray;
                }
            }

            .day {
                display: block;
                font-size: 0.4em;
                color: @gray;
                text-transform: uppercase;
                font-weight: 600;
            }
        }

        .dayname {
            margin: 0;
            color: @blue;
            font-size: @f18;
            font-weight: 600;
        }
    }

    .namedays {
        border-bottom: 1px solid @light-gray;
        min-height: 60px;
        padding: 0;
        text-align: center;

        .official,
        .inofficial,
        .finnish {
            float: left;
            width: (100%/3);

            h3 {
                margin-bottom: 0;
                font-size: @f14;
            }

            ul {
                list-style: none;
                padding: 0;
                margin: 10px 0 20px;

                li {
                    color: @blue;
                    font-size: @f14;
                    font-weight: 600;
                }
            }
        }
    }

    .holidays {
        .holiday {
            border-bottom: 1px solid @light-gray;
            padding: 20px 0 5px;
            position: relative;

            &:last-child {
                border-bottom: 0;
            }

            .icon {
                float: left;
                font-size: 40px;
                margin-top: 4px;
                color: @middle-gray;

                &.newyear {
                    background: @middle-gray;
                    border-radius: 50px;
                    padding: 7px;
                    margin-left: -7px;
                    color: @white;
                }
            }

            // Tooltip
            a.icon {
                position: relative;

                &::after {
                    content: attr(title);
                    background-color: @light-blue;
                    padding: 5px 8px;
                    opacity: 0;
                    position: absolute;
                    bottom: -20px;
                    left: 0;
                    color: @blue;
                    font-size: 0.4em;
                    font-family: @ff-normal;

                    .transition(opacity 0.35s);
                }

                &.tooltip::after {
                    opacity: 1;
                }
            }

            .hwrap {
                line-height: 40px;
                margin-left: 55px;
            }

            .title {
                display: inline-block;
                margin: 0;
                color: @blue;
                font-size: @f18;
                font-weight: 600;
                line-height: 1.2;
                vertical-align: middle;
            }
        }
    }

    // Swiping behavior
    &.swiping {
        position: absolute;
        left: 0;
        right: 0;
        z-index: 2;

        &,
        header {
            .transform(translateX(0%));
            .transform-transition(transform 350ms ease-out);
        }

        // Swiping to the left, so coming in from right
        &.left,
        &.left header {
            -webkit-transform: translateX(100%);
        }

        &.right,
        &.right header {
            -webkit-transform: translateX(-100%);
        }

        &.begone {
            z-index: 1;
        }
    }
}
