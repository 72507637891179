@font-face {
    font-family: 'svenskadagar';
    src: url('@{assets-path}fonts/svenskadagar.eot?-paceug');
    src:
        url('@{assets-path}fonts/svenskadagar.eot?#iefix-paceug') format('embedded-opentype'),
        url('@{assets-path}fonts/svenskadagar.woff?-paceug') format('woff'),
        url('@{assets-path}fonts/svenskadagar.ttf?-paceug') format('truetype'),
        url('@{assets-path}fonts/svenskadagar.svg?-paceug#svenskadagar') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^='icon-'], [class*=' icon-'] {
}

.icon {
    font-family: 'svenskadagar', sans-serif;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &:hover {
        text-decoration: none;
    }

    &.transmit {
        &::before {
            content: '\74';
        }
    }

    &.flag,
    &.icke-religios {
        &::before {
            content: '\66';
        }
    }

    &.sikhism {
        &::before {
            content: '\73';
        }
    }

    &.right {
        &::before {
            content: '\3e';
        }
    }

    &.plus {
        &::before {
            content: '\2b';
        }
    }

    &.minus {
        &::before {
            content: '\2013';
        }
    }

    &.left {
        &::before {
            content: '\3c';
        }
    }

    &.kristendom {
        &::before {
            content: '\6b';
        }
    }

    &.judendom {
        &::before {
            content: '\6a';
        }
    }

    &.islam {
        &::before {
            content: '\69';
        }
    }

    &.hinduism {
        &::before {
            content: '\68';
        }
    }

    &.burger {
        &::before {
            content: '\3d';
        }
    }

    &.buddhism {
        &::before {
            content: '\62';
        }
    }
}

/*
.icon {
  background-image: url(@{assets-path}/img/icons.png);
  background-size: (312px/2) auto;

  &.buddhism-newyear {
    background-position {
    &: -78px/2 0px/2;
    width: floor(78px/2);
    height: floor(78px/2);
  }
  &.buddhism {
    background-position {
    &: -156px/2 -156px/2;
    width: floor(78px/2);
    height: floor(78px/2);
  }
  &.calendar {
    background-position {
    &: -198px/2 -234px/2;
    width: floor(42px/2);
    height: floor(43px/2);
  }
  &.cart {
    background-position {
    &: -156px/2 -234px/2;
    width: floor(42px/2);
    height: floor(43px/2);
  }
  &.hinduism-newyear {
    background-position {
    &: -156px/2 0px/2;
    width: floor(78px/2);
    height: floor(78px/2);
  }
  &.hinduism {
    background-position {
    &: -156px/2 -78px/2;
    width: floor(78px/2);
    height: floor(78px/2);
  }
  &.icke-religios-newyear {
    background-position {
    &: 0px/2 -156px/2;
    width: floor(78px/2);
    height: floor(78px/2);
  }
  &.icke-religios {
    background-position {
    &: -78px/2 -156px/2;
    width: floor(78px/2);
    height: floor(78px/2);
  }
  &.islam-newyear {
    background-position {
    &: 0px/2 0px/2;
    width: floor(78px/2);
    height: floor(78px/2);
  }
  &.islam {
    background-position {
    &: -234px/2 0px/2;
    width: floor(78px/2);
    height: floor(78px/2);
  }
  &.judendom-newyear {
    background-position {
    &: -234px/2 -78px/2;
    width: floor(78px/2);
    height: floor(78px/2);
  }
  &.judendom {
    background-position {
    &: -234px/2 -156px/2;
    width: floor(78px/2);
    height: floor(78px/2);
  }
  &.kristendom-newyear {
    background-position {
    &: 0px/2 -234px/2;
    width: floor(78px/2);
    height: floor(78px/2);
  }
  &.kristendom {
    background-position {
    &: -78px/2 -78px/2;
    width: floor(78px/2);
    height: floor(78px/2);
  }
  &.sikhism-newyear {
    background-position {
    &: 0px/2 -78px/2;
    width: floor(78px/2);
    height: floor(78px/2);
  }
  &.sikhism {
    background-position {
    &: -78px/2 -234px/2;
    width: floor(78px/2);
    height: floor(78px/2);
  }
}
*/
