.head {
    background: @blue;
    padding: 10px @base-padding;
    overflow: hidden;
    position: relative;
    color: @white;

    .title {
        display: block;
        margin: 0;
        float: left;
        font-size: @f14;
    }

    .toggle {
        float: right;

        .icon {
            display: inline-block;
            margin-left: 5px;
            margin-top: -2px;
            font-size: 24px;
            vertical-align: middle;
        }
    }

    .menu {
        background-color: @dark-gray;
        margin: 33px -@base-padding -10px;
        overflow: hidden;
        clear: both;

        .topnav {
            ul {
                margin: 0;
                padding: 0;
                font-size: @f16;
                list-style: none;

                li {
                    .icon {
                        float: right;
                        margin: 7px 0 0;
                        color: @gray;
                        font-size: 25px;
                    }

                    a {
                        border-bottom: 1px solid fade(@gray, 80%);
                        display: block;
                        margin-left: 0;
                        padding: 6px 0;

                        &:hover {
                            text-decoration: none;
                        }
                    }

                    &:last-child a {
                        border-bottom: none;
                    }

                    &.active,
                    &:hover {
                        a {
                            color: @blue;
                        }

                        .icon {
                            color: @medium-blue;
                        }
                    }
                }
            }
        }

        .cta {
            margin: 0 0 -10px;

            .subscribe,
            .order {
                background: @blue;
                border-radius: 3px;
                margin: 15px 0;
                padding: 10px 20px;

                > span {
                    color: fade(@white, 60%);
                    font-size: @f12;
                }
            }

            .btn {
                display: block;
                margin-top: -3px;
                color: @white;
                font-size: @f16;
                text-align: left;
                white-space: nowrap;

                .icon {
                    float: right;
                    font-size: 30px;
                    margin-top: -10px;
                    margin-right: -5px;
                }
            }
        }
    }

    // Slide it down
    .menu {
        display: block;
        max-height: 0;
        padding: 0 @base-padding;
        .transition(max-height 0.35s ease-out, padding 0.2s ease-out;);
    }

    .toggle-checkbox:checked ~ .menu {
        max-height: 600px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

.arrow-nav {
    opacity: 1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    .transition(opacity 1s ease-out);

    button {
        -webkit-appearance: none;
        background-color: transparent;
        border: none;
        padding: 10px;
        margin: 0;
        position: absolute;
        top: 16px;
        z-index: 10;
        color: @blue;
        outline: none;
    }

    .left {
        left: 40px;
    }

    .right {
        right: 40px;
    }

    &.hide {
        .transition(none);

        opacity: 0;
    }
}
