.before,
.after {
    overflow: hidden;
    display: inline-block;
    height: 1000px; // Initial, overwritten by js
    margin-bottom: -10px; // Hack to remove weird empty space
    color: @gray;

    .day {
        background: @light-gray;
        border-left: 1px solid @white;
        width: 90px;
        margin-bottom: -1000px;
        padding-bottom: 1000px;
        overflow: hidden;
        position: relative;
        z-index: 5;
        text-align: center;

        .transition(opacity 1s);

        &:hover {
            background: @middle-gray;
            text-decoration: none;
        }

        &.fadein {
            opacity: 0;
        }

        h2 {
            height: 121px;
            margin: 15px 0 0;
            font-size: @f30;
            font-weight: normal;
        }

        .namedays {
            height: 131px;
            padding: 0;
            list-style: none;

            li {

            }
        }

        .holidays {
            list-style: none;
            padding: 0;

            li {
                display: inline-block;
                opacity: 0.15;
                margin: 10px;

                .icon {
                    font-size: 40px;
                }
            }
        }
    }
}

.before {
    margin-left: 0.4em;
}
