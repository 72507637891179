.logo--blue {
    display: none;
}

.today {
    width: @max-width - 120px;
    margin: 0 auto;
    padding: 15px 60px 60px;
    height: auto;

    header {
        height: 90px;
        text-align: left;

        h1 {
            .day {
                float: right;
                color: inherit;
                font-size: 1em;
                font-weight: inherit;
                text-transform: none;
            }
        }
    }

    .namedays {
        border-bottom: 1px solid @light-gray;
        min-height: 142px;
        padding: 0 50px 0 75px;
        text-align: left;

        .official,
        .inofficial,
        .finnish {
            ul {
                margin: 20px 0;

                li {
                    font-size: @f18;
                }
            }
        }
    }

    .holidays {
        .holiday {
            padding: 20px 50px 10px 75px;

            .hwrap {
                line-height: inherit;
                margin-left: 0;
            }

            h2 {
                margin: 15px 0 0;
            }

            .icon {
                position: absolute !important;
                top: 20px;
                left: 15px;
                margin-top: 0;
            }
        }
    }

    &.swiping {
        position: static;
        z-index: 0;

        // Compensate for that when two articles are next to each other the will
        // push each other to the side
        + .swiping {
            margin-left: -@max-width;
        }
    }
}
