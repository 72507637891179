.article {
    background-color: transparent;
    width: @max-width;
    padding: 0;

    > header {
        background-color: transparent;
        border: none;
        margin: 0 0 60px;
        padding-top: 50px;
        text-align: center;

        .icon {
            font-size: 60px;
        }

        h1 {
            font-size: @f40;
        }
    }

    .holiday-toggle,
    .holidays {
        background-color: @white;
        width: 400px;
        margin: 0 0 0 40px;
        float: right;
    }

    .toggle {
        cursor: default;
    }

    .holiday-toggle {
        background-color: @white;
        width: 340px;
        padding: 22px 30px 10px;

        .icon {
            display: none;
        }
    }

    .holidays {
        margin-bottom: 40px;
        padding-bottom: 20px;
        clear: both;
        font-size: @f12;

        .holiday {
            margin: 7px 30px;
            padding: 0;

            .hwrap {
                padding: 1em 0;

                h3 {
                    line-height: 1.3;
                }

                .icon {
                    display: none;
                }
            }

            .toggle-target {
                color: @gray;
                line-height: 1.4;
            }
        }
    }

    // Cancel mobile toggle
    .toggle-target,
    .toggle-checkbox:checked ~ .toggle-target,
    .holidays .toggle-checkbox:checked ~ .toggle-target {
        max-height: none;
        padding: 0;
        .transition(none);
    }

    .content {
        background-color: transparent;
        margin: 40px 0 0;
        padding: 0;
        text-align: justify;

        a {
            opacity: 0.8;
            transition: 0.2s;

            &:hover {
                opacity: 1;
            }
        }
    }

    .content_site-credits {
        border-top: 1px solid @blue;
        color: @blue;

        p {
            margin-bottom: 0;
        }

        span {
            font-weight: 600;
        }

        a {
            text-decoration: underline;
        }

        img {
            width: 80px;
            margin-top: 12px;
            margin-bottom: 30px;
        }
    }
}
