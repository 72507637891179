.transition (...) {
    -webkit-transition: @arguments;
    -moz-transition: @arguments;
    -ms-transition: @arguments;
    -o-transition: @arguments;
    transition: @arguments;
}

.transform (...) {
    -webkit-transform: @arguments;
    -moz-transform: @arguments;
    -ms-transform: @arguments;
    -o-transform: @arguments;
    transform: @arguments;
}

.transform-transition (...) {
    -webkit-transition: ~'-webkit-@{arguments}';
    -moz-transition: ~'-moz-@{arguments}';
    -ms-transition: ~'-ms-@{arguments}';
    -o-transition: ~'-o-@{arguments}';
    transition: @arguments;
}

.animation (...) {
    -webkit-animation: @arguments;
    -moz-animation: @arguments;
    -ms-animation: @arguments;
    -o-animation: @arguments;
    animation: @arguments;
}

.filter (@filter) {
    -webkit-filter: @filter;
    -moz-filter: @filter;
    -ms-filter: @filter;
    -o-filter: @filter;
    filter: @filter;
}

.stripe (@color, @size: 100%) {
    background-image: -webkit-gradient(linear, left top, right top, color-stop(0.5, @color), color-stop(0.5, transparent), to(transparent));
    background-image: -webkit-linear-gradient(left, @color 50%, transparent 50%, transparent);
    background-image: -moz-linear-gradient(left, @color 50%, transparent 50%, transparent);
    background-image: -o-linear-gradient(left, @color 50%, transparent 50%, transparent);
    background-image: -ms-linear-gradient(left, @color 50%, transparent 50%, transparent);
    background-image: linear-gradient(to right, @color 50%, transparent 50%, transparent);
    background-size: @size @size;
}

.gradient (@start-color: #555; @end-color: #333; @start-percent: 0%; @end-percent: 100%) {
    background-image: -webkit-gradient(linear, left @start-percent, left @end-percent, from(@start-color), to(@end-color)); // Safari 4+, Chrome 2+
    background-image: -webkit-linear-gradient(top, @start-color, @start-percent, @end-color, @end-percent); // Safari 5.1+, Chrome 10+
    background-image: -moz-linear-gradient(top, @start-color @start-percent, @end-color @end-percent); // FF 3.6+
    background-image: linear-gradient(to bottom, @start-color @start-percent, @end-color @end-percent); // Standard, IE10
    background-repeat: repeat-x;
    filter: e(%("progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=0)", argb(@start-color), argb(@end-color))); // IE9 and down
}

.placeholder (@color) {
    &::-webkit-input-placeholder {
        color: @color;
    }

    &:-moz-placeholder {
        color: @color;
    }

    &::-moz-placeholder {
        color: @color;
    }

    &:-ms-input-placeholder {
        color: @color;
    }
}
