.article {
    padding-bottom: 0;

    > header {
        background-color: @light-blue;
        margin: -15px -@base-padding 0;
        padding: 30px 0 10px;
        color: @blue;

        .icon {
            font-size: 30px;
        }

        h1 {
            margin-top: 0;
        }
    }

    .holiday-toggle {
        font-size: @f18;

        .icon {
            display: inline-block;
            float: right;
            color: @blue;
            font-size: 30px;
        }
    }

    // Turn plusses into minuses when their checkbox is checked
    .toggle-checkbox:checked ~ .toggle .icon {
        .icon.minus;
    }

    .holidays {
        .holiday {
            border-top: 1px solid @light-gray;
            border-bottom: none;
            margin: 0;
            padding: 0;

            .hwrap {
                display: block;
                margin: 0;
                padding: 13px 30px 10px 0;
                line-height: 1.5;

                .icon {
                    position: absolute;
                    top: 18px;
                    right: 5px;
                    color: @gray;
                    font-size: 20px;
                }

                p,
                h3 {
                    margin: 0;
                    font-size: @f14;
                    line-height: 1;
                }

                p {
                    margin-bottom: 2px;
                }
            }

            p {
                margin: 0;
            }
        }
    }

    // Slide holidays down
    .toggle-target {
        display: block;
        max-height: 0;
        padding: 0;
        overflow: hidden;
        .transition(max-height 0.3s ease-in-out, padding 0.35s ease-out;);
    }

    .toggle-checkbox:checked ~ .toggle-target {
        max-height: 3000px;
    }

    .holidays .toggle-checkbox:checked ~ .toggle-target {
        max-height: 500px;
        padding: 1em 0;
    }

    .content {
        background-color: @light-blue;
        margin: 0 -@base-padding;
        padding: @base-padding;
    }

    .content_site-credits {
        border-top: 1px solid @blue;
        color: @blue;

        p {
            margin-bottom: 0;
        }

        span {
            font-weight: 600;
        }

        a {
            text-decoration: underline;
        }

        img {
            width: 70px;
            margin-top: 12px;
            margin-bottom: 30px;
        }
    }
}
