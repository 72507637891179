.head {
    max-height: 90%;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;

    .title,
    .toggle {
        margin-bottom: -5px;
        font-size: 1.9em;
    }

    .title {
        margin-top: 0;
        float: left;
    }

    .toggle {
        .icon {
            font-size: 33px;
        }
    }

    .menu {
        margin-top: 53px;
        position: relative;

        .topnav,
        .subscribe,
        .order {
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            float: left;
        }

        .topnav {
            width: 28%;

            ul {
                font-size: @f20;

                li {
                    .icon {
                        font-size: 30px;
                        margin-top: 8px;
                    }

                    a {
                        margin: 0;
                        padding-left: 0;
                    }
                }
            }
        }

        .logo {
            position: absolute;
            opacity: 0.28;
            bottom: 55px;
            left: 30px;
            max-width: 90px;
            transition: 0.2s;
        }

        .logo:hover {
            opacity: 1;
        }

        .cta {
            margin: 0;

            .subscribe,
            .order {
                background-color: @darker-gray;
                border-radius: 0 0 2px 2px;
                width: 33%;
                margin-left: 3%;
                padding: 0;

                img {
                    display: block;
                    max-width: 100%;
                    max-height: 300px;
                    margin: auto;
                }

                span {
                    border-bottom: 1px solid @dark-gray;
                    display: block;
                    min-height: 47px;
                    padding: 37px 20px 18px;
                    color: @white;
                    font-size: @f16;
                    text-align: center;
                }
            }

            .btn {
                background: @blue;
                border-radius: 0 0 2px 2px;
                margin: 0;
                padding: 15px 30px 13px;
                overflow: hidden;
                line-height: 37px;

                .icon {
                    margin-top: -3px;
                    font-size: 40px;
                }
            }
        }
    }

    // Slide it down
    .menu {
        padding: 0 30px;
        overflow: hidden;
        .transition(max-height 0.1s ease-out);
    }

    .toggle-checkbox:checked ~ .menu {
        max-height: 600px;
        padding-top: 40px;
        padding-bottom: 40px;
        .transition(max-height 0.35s ease-out);
    }
}
