@import 'icons';
@import 'head';
@import 'fullday';
@import 'article';
@import 'smallday';

.wrap {
    position: relative;
}

.spinner {
    border-width: 6px;
    border-style: solid;
    border-color: rgba(21, 134, 179, 0.8) rgba(21, 134, 179, 0.15) rgba(21, 134, 179, 0.15);
    border-radius: 100%;
    height: 30px;
    width: 30px;
    margin-top: -15 - 6px;
    margin-left: -15 - 6px;
    position: absolute;
    top: 80px;
    left: 50%;
    z-index: -1;

    .animation(rotation 0.6s infinite linear);

    opacity: 0;
    .transition(opacity 0.4s);

    &.spin {
        .transition(none);

        opacity: 1;
    }
}
