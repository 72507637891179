body {
    font: @fbase/1.5 @ff-normal;
    font-weight: 100;
    color: @black;
    -webkit-text-size-adjust: 100%;
}

body,
html {
    height: 100%;
    // Sideways scroll-fix
    max-width: 100%;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
}

h1, h2, h3, h4 {
    font-weight: normal;
}

a {
    color: inherit;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

.content a {
    text-decoration: underline;
}

// IE-warning
.browsehappy {
    background: #fdf2ab no-repeat 1em 0.55em url(http://browser-update.org/img/dialog-warning.gif);
    border-bottom: 1px solid #a29330;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    text-align: left;
    font-family: Arial, Helvetica, sans-serif;
    color: #000;
    font-size: 12px;

    p {
        margin: 0;
        padding: 5px 36px 5px 40px;
    }

    a {
        color: #e25600;
    }
}

.page {
    background-color: @light-blue;

    .today {
        background-color: transparent;
    }
}

/* Animations
============================================================================= */

@-webkit-keyframes rotation {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(359deg);
    }
}

@-moz-keyframes rotation {
    from {
        -moz-transform: rotate(0deg);
    }

    to {
        -moz-transform: rotate(359deg);
    }
}

@-o-keyframes rotation {
    from {
        -o-transform: rotate(0deg);
    }

    to {
        -o-transform: rotate(359deg);
    }
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(359deg);
    }
}
