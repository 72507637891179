@import 'icons';
@import 'head';

/* Day collapsing/spreading
============================================================================= */

// Don't let the wrapper overflow
body {
    overflow-x: hidden;
}

html,
body {
    // Sideways scroll-fix
    max-width: 100%;
    overflow-x: hidden;
}

// Center the contents and keep them centered even if overflowing
// Also remove the spaces inbetween the secitons
.wrap {
    width: 300%;
    margin-left: -100%;
    margin-bottom: 60px; // Compensation for footer
    text-align: center;
    word-spacing: -4px;
}

.spinner {
    display: none;
}

// Display inline to allow centering
// Also restore the spacing and alignment
.today,
.day {
    display: inline-block;
    text-align: left;
    word-spacing: 0;
    vertical-align: top;
}

// These should not impact flow
.after,
.before {
    display: inline;
}

/* stylelint-disable no-invalid-position-at-import-rule */
@import 'fullday';
@import 'article';
@import 'smallday';
/* stylelint-enable no-invalid-position-at-import-rule */
